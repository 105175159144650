/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, jsx, Themed} from 'theme-ui'
import React from 'react'

type Props = {
  title: string
  items: string[]
}

export const TitledList = ({title, items}: Props) => (
  <Box sx={{
    textAlign: 'center',
  }}>
    <Themed.h3 sx={{
      fontWeight: 'heavy',
      marginBottom: ['2rem', '1rem'],
      lineHeight: '1',
    }}>
      {title}
    </Themed.h3>
    <ul sx={{
      listStyle: 'none',
      margin: 0,
      padding: 0,
      li: {
        fontWeight: 'bold',
        '&:not(:last-child)': {
          marginBottom: ['1rem', '0.2rem'],
        }
      },
    }}>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </Box>
)
