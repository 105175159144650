/** @jsx jsx */
// noinspection ES6UnusedImports
import {Box, jsx, Themed} from 'theme-ui'
import React from 'react'
import {Layout} from '../layout/layout'
import {StaticImage} from 'gatsby-plugin-image'
import {TitledList} from '../layout/titled-list'
import {SectionGray, SectionImage, SectionWhite} from '../layout/section'

const PartnersAndProjects = () => (
  <Layout>
    <SectionImage image={(
      <StaticImage
        sx={{
          width: '100%',
          height: '100%',
        }}
        loading="eager"
        layout="fullWidth"
        src="../images/careers.jpg"
        alt=""/>
    )}>
      <Themed.h1 sx={{
        color: 'white',
        marginBottom: ['2rem', '1rem'],
        fontWeight: 'regular',
      }}>
        Want to learn more?
        <br/>
        Drop us an email.
      </Themed.h1>

      <div sx={{
        fontWeight: 'regular',
      }}>
        We’d be more than happy to walk you through our process. Contact us at
        {' '}
        <a
          sx={{
            color: 'atxOrange',
            textDecoration: 'underline',
          }}
          href="mailto:info@ATXDefense.com"
          target="_blank"
          rel="noreferrer">
          info@ATXDefense.com
        </a>.
      </div>
    </SectionImage>

    <SectionWhite>
      <Themed.h1 sx={{
        textAlign: ['center', 'initial'],
        color: 'atxOrange',
        fontWeight: 'bold',
        mb: '1rem'
      }}>
        Want to join the ATX Defense Team?
      </Themed.h1>
      <Box sx={{textAlign: ['center', 'initial']}}>
        We’re always on the lookout for client-focused, adaptable, self-motivated problem-solvers to help us bring our
        transformations to life.
      </Box>
    </SectionWhite>

    <SectionGray>
      <Box sx={{
        padding: '2rem',
        backgroundColor: '#F1F1F2',
      }}>
        <TitledList
          title=" How We Take Care of Our Employees"
          items={[
            'Competitive Compensation',
            '100% company-paid health insurance premiums for employees and dependents',
            '4 weeks PTO per year + all Federal holidays',
            '401(k) with matching and immediate vesting',
            'A small, close-knit team with an infectious atmosphere',
            'Company activities, like Austin FC games and happy hours',
            'Office and client locations in downtown Austin',
          ]}
        />
      </Box>
    </SectionGray>
  </Layout>
)

export default PartnersAndProjects
